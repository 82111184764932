import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import Layout from '../layouts/layout'
import SEO from '../components/seo'
import Jumbotron from '../components/podcast/jumbotron'
import List from '../components/podcast/list'

const podcasts = [
  {
    id: 1,
    title: 'Podcast Title',
    duration: 360,
    description:
      'Minim consectetur ad magna id in excepteur. Anim elit cillum tempor ut duis quis ut ullamco excepteur. Aute esse consequat amet adipisicing culpa. Velit ad ex esse irure in.'
  },
  {
    id: 2,
    title: 'Podcast Title 2',
    duration: 360,
    description:
      'Minim consectetur ad magna id in excepteur. Anim elit cillum tempor ut duis quis ut ullamco excepteur. Aute esse consequat amet adipisicing culpa. Velit ad ex esse irure in.'
  },
  {
    id: 3,
    title: 'Podcast Title 3',
    duration: 360,
    description:
      'Minim consectetur ad magna id in excepteur. Anim elit cillum tempor ut duis quis ut ullamco excepteur. Aute esse consequat amet adipisicing culpa. Velit ad ex esse irure in.'
  }
]

const Podcast = ({ data, location }) => {
  useEffect(() => {
    navigate('/')
  })

  return (
    <Layout location={location} title="Podcast">
      <SEO title="Podcast" />
      <div
        className="flex flex-col lg:flex-row justify-start flex-wrap"
        style={{
          color: 'var(--textNormal)',
          transition: 'color 0.2s ease-out, background 0.2s ease-out'
        }}
      >
        <Jumbotron />
        <List podcasts={podcasts} />
      </div>
    </Layout>
  )
}

export default Podcast
