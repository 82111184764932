import React from 'react'

const Item = ({ item = {} }) => {
  return (
    <div className="flex items-center border w-full mb-2 px-4 py-8 cursor-pointer hover:bg-gray-100">
      <div className="flex items-center justify-center ml-2 mr-6 md:mx-10">
        <svg
          className="fill-current hover:text-red-400 h-12 w-12"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM7 6l8 4-8 4V6z" />
        </svg>
      </div>
      <div>
        <div className="flex text-xs">
          <div className="title mr-4">EPISODE: {item.id}</div>
          <div className="duration">Duration: {item.duration}</div>
        </div>
        <div className="font-bold py-4">{item.title}</div>
        <div>{item.description}</div>
      </div>
    </div>
  )
}

export default Item
