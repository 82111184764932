import React from 'react'
import Item from './item'

const List = ({ podcasts = [] }) => {
  return (
    <div className=" mb-8">
      <div className="text-xl mb-4 pl-0 md:pl-4">All Podcasts</div>
      {podcasts.map((podcast, i) => (
        <Item item={podcast} key={i} />
      ))}
    </div>
  )
}

export default List
