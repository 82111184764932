import React from 'react'

const Jumbotron = () => {
  return (
    <div className="flex flex-col md:flex-row mt-4 mb-12">
      <div className="flex flex-col w-full md:w-3/6">
        <div className="text-3xl font-bold  mb-4">Cast D!</div>
        <div className=" mb-10 pr-8">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry’s standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged.
        </div>

        <div className="md:hidden flex justify-center items-center  border w-full h-30-v mb-10">
          Player
        </div>

        <div className="flex flex-col md:mb-0 md:pr-40">
          <div className=" text-center border px-4 py-2 mb-2 cursor-pointer hover:bg-gray-100 hover:text-red-400">
            Play in Google Podcast
          </div>
          <div className="flex flex-row w-full">
            <div className=" text-center border px-4 py-2 mb-2 mr-2 w-3/6 cursor-pointer hover:bg-gray-100 hover:text-red-400">
              Play in Spotify
            </div>
            <div className=" text-center border px-4 py-2 mb-2 w-3/6 cursor-pointer hover:bg-gray-100 hover:text-red-400">
              Play in iTunes
            </div>
          </div>
        </div>
      </div>
      <div className="hidden md:flex justify-center items-center  border w-3/6">
        Player
      </div>
    </div>
  )
}

export default Jumbotron
